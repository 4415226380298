import React, { useState } from "react";
import "../../styles/cart.css";

const CouponAlert = () => {
  const [showAlert, setShowAlert] = useState(true);
  console.log()

  return (
    <>
      {showAlert && (

        <div className="d-flex p-2 bg-dark text-white">
          <div className="alertText p-1 justify-content-center align-items-center mx-auto text-center">
            <span style={{textTransform:"uppercase"}}>Glow up for the festive season✨! Call <a href="tel:+919266546444"
              style={{ color: "white", textDecoration: "underline" }}>+919266546444</a> to book treatments </span>
              {/* <span style={{textTransform:"uppercase"}}>New Avataar Skincare app for iOS is live. Update now!</span> */}
              {/* <span>
              New Treatment Alert! <a href="https://avataarskin.com/advanced-laser-treatments/prp-treatment-not-active-153" style={{ color: "white", textDecoration: "underline" }}>GFC for Face & Hair</a>
              </span> */}
          </div>

          <div className="justify-content-center align-items-center">
            <span aria-hidden="true" onClick={() => setShowAlert(false)} className="alertPointer"><strong>&times;</strong></span>
          </div>

        </div>

      )}
    </>
  );
};

export default CouponAlert;
